<template>
  <CBox>
    <CBox v-for="(label, i) in filterLabels" :key="i">
      <CText
        py="4px"
        mx="20px"
        mb="2"
        fontFamily="Montserrat"
        fontStyle="normal"
        fontSize="14px"
        fontWeight="600"
        color="fontMain"
      >
        Pilih Arena
      </CText>
      <CGrid
        :templateColumns="[
          '12fr',
          '6fr 6fr',
          '3fr 3fr 3fr 3fr',
          '3fr 3fr 3fr 3fr',
        ]"
        :gap="[2, 2, 6, 6]"
        w="100%"
      >
        <CBox
          bg="white"
          minH="100px"
          py="20px"
          px="20px"
          borderRadius="5px"
          boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
          mb="5"
          v-for="(filter, idx) in displayedFilters[label.toLowerCase()]"
          :key="idx"
        >
          <CText
            class="pointer"
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="18px"
            :fontWeight="600"
            color="activeMenu"
            @click.native="
              $router.push({
                name: 'CompetitionArena',
                params: {
                  id,
                  arena: filter.slug,
                },
              })
            "
          >
            {{ filter.name }}
          </CText>
          <CText fontFamily="Montserrat" fontStyle="normal" fontSize="14px">
            {{ filter.description }}
          </CText>
        </CBox>
      </CGrid>
    </CBox>
  </CBox>
</template>

<script>
import { GET_LEADERBOARDS_FILTERS } from "@/store/leaderboard-new.module";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      displayedFilters: {},
      filterLabels: [],
      timeFilters: [
        { name: "Show All", value: "all" },
        { name: "This Year", value: "yearly" },
        { name: "This Month", value: "monthly" },
      ],
    };
  },
  watch: {
    id() {
      this.getData();
    },
    currentLeaderboardsFilters(val) {
      this.getFilterLabels(val);
      this.getFilterDatas(val);
    },
  },
  computed: {
    currentLeaderboards() {
      return this.$store.getters.currentNewLeaderboards;
    },
    currentLeaderboardsFilters() {
      return this.$store.getters.currentNewLeaderboardsFilters;
    },
    currentLeaderboardsActiveFilters() {
      return this.$store.getters.currentNewLeaderboardsActiveFilters;
    },
    currentLeaderboardsActiveGroup() {
      return this.$store.getters.currentNewLeaderboardsActiveGroup;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    setFilterType(type) {
      switch (type) {
        case "province":
          return "province";
        case "schools":
          return "school";
        case "hashtags":
          return "hashtag";
        default:
          return "main";
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getFilterDatas(data) {
      this.displayedFilters = data;
    },
    getFilterLabels(filters) {
      const labels = Object.keys(filters);
      if (labels.length !== 0) {
        labels.map((label) => {
          if (
            label == "arena" &&
            !this.filterLabels.includes(this.capitalizeFirstLetter(label))
          )
            this.filterLabels.push(this.capitalizeFirstLetter(label));
        });
      }
    },
    async getData() {
      try {
        if (this.id) {
          await this.$store.dispatch(GET_LEADERBOARDS_FILTERS, {
            id: this.id,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
