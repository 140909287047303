<template>
  <CBox>
    <CBox>
      <Loading :active="isLoading" />
      <CGrid
        :templateColumns="
          currentAppType.view_type == 'RECRUITMENT'
            ? '12fr'
            : ['12fr', '12fr', '12fr', '12fr']
        "
        :gap="[3, 3, 6, 6]"
      >
        <CBox>
          <CBox
            bg="white"            
            borderRadius="10px"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
          >
            <CBox minH="10px">
              <CBox py="20px" px="20px">
                <CText
                  whiteSpace="wrap"                  
                  fontFamily="Montserrat"
                  :fontSize="['20px', '24px', '34px', '36px']"
                  fontWeight="bolder"
                  color="fontMain"
                  >{{ currentAppType.name }}</CText
                >                                                                
              </CBox>
            </CBox>
            <CDivider />
            <!-- <CBox my="30px" mx="20px">
              <CText
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="20px"
                fontWeight="bold"
                color="fontMain"
                >Detail Instruksi</CText
              >
            </CBox> -->            
            <CBox
              v-if="isReachDeadline() || !isChallengeBegin()"
              px="10px"
              py="10px"
              borderRadius="4px"
              bgColor="bgFailedState"
            >
              <CText
                display="inline"
                fontFamily="Montserrat"
                color="failedColor"
                fontWeight="bold"
                fontSize="14px"
                >{{
                  !isChallengeBegin()
                      ? currentAppType.alert_message.submissionNotStarted +
                        " - " +
                        this.formatToLocalDate(currentAppType.start_date)
                      : currentAppType.alert_message.submissionClosed                    
                }}</CText
              >
              </CBox>
              <CBox v-if="!currentUser" px="10px"
              py="10px"
              borderRadius="4px"
              >

              <CText                
                display="inline"
                fontFamily="Montserrat"
                color="failedColor"
                fontWeight="bold"
                fontSize="14px"
                >{{  currentAppType.alert_message.unauthorizedUser  }},<span
                  style="color: #29aafe; cursor: pointer"
                  @click="redirectToSignIn"
                >
                  Click here</span
                >
                to login</CText
              >
            </CBox>
            <CBox
              px="20px"
              pt="40px"
              pb="20px"
              v-if="currentAppType.type !== 'LOGIC'"
            >
              <CText
                fontFamily="Montserrat"
                fontSize="20px"
                color="fontMain"
                fontWeight="bold"
                >Submit Form</CText
              >
            </CBox>
            <CBox
              px="20px"
              v-if="
                !checkEmpObject(currentAppType) &&
                currentAppType.type !== 'LOGIC'
              "
            >                                          
                            
              <CFormControl v-if="currentAppType.expired_date" mb="20px">
                <CGrid
                  :templateColumns="['12fr', '12fr', '1fr 3fr', '1fr 3fr']"
                  :gap="[0, 0, 6, 6]"
                  w="100%"
                >
                  <CFormLabel
                    fontFamily="Montserrat"
                    color="fontMain"
                    fontSize="14px"
                    fontWeight="bold"
                    d="flex"
                    alignItems="center"
                    >{{ currentAppType.form_label.deadline }}</CFormLabel
                  >
                  <CText
                    fontFamily="Montserrat"
                    color="fontMain"
                    fontWeight="550"
                    fontSize="14px"
                  >
                    {{
                      formatExpiredDate(
                        currentAppType.view_type,
                        currentAppType.expired_date
                      )
                    }}</CText
                  >
                </CGrid>
              </CFormControl>
            </CBox>
            <CBox
              d="flex"
              justifyContent="flex-end"
              :m="[
                '20px 20px 13px 20px',
                '20px 20px 13px 20px',
                '20px 20px 20px 20px',
                '20px 20px 20px 20px',
              ]"
              pb="20px"
            >
              <CButton
                variant="outline"
                backgroundColor="#fff"
                mr="2"
                color="main"
                borderColor="main"
                fontSize="14px"
                @click="setClipboard()"
              >
                <CImage
                  :src="require('@/assets/icon/copy.svg')"
                  w="18px"
                  h="18px"
                  mr="2"
                />
                Copy Challenge URL
              </CButton>              
            </CBox>
          </CBox>          
        </CBox>        
      </CGrid>
    </CBox>
    <CBox v-if="currentAppType.view_type != 'RECRUITMENT'" pb="2em">          
          <Arena
            v-if="currentAppType.id"
            :id="parseInt(currentAppType.id)"
          />
        </CBox>
  </CBox>
</template>

<script>
import {
  CBox,
  CGrid,
  CText,
  // CStack,
  CImage,
  CFormControl,
  CFormLabel,
  // CInput,
  // CRadioGroup,
  // CRadio,
} from "@chakra-ui/vue";
// import ButtonBlue from "@/components/button/ButtonBlue.vue";
// import Draggable from "@/components/Draggable.vue";
// import JobResultItem from "@/components/JobResultItem.vue";
import { GET_APP_TYPE } from "@/store/app-types.module";
import { GET_APP_TYPE_VIEW } from "@/store/app-type-view.module";
// import { GET_TEST_SCENARIOS } from "@/store/test-scenarios.module";
import {
  POST_TEST,
  GET_TESTS,
  GET_TEST_SUMMARY,
  UPLOAD_APP,
  RESET_PROGRESS_BAR,
} from "@/store/test.module";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";
// import Select2 from "v-select2-component";
import { validationMixin } from "vuelidate";
import moment from "moment-timezone";
import 'moment-timezone';
import axios from "axios";
import { required, url } from "vuelidate/lib/validators";
// import LeaderboardNew from "@/components/LeaderboardNew.vue";
import Arena from "@/components/Arena.vue";
import _ from "lodash";
import config from "@/ConfigProvider";
import ApiService from "@/store/api.service";

export default {
  metaInfo() {
    const appName = this.currentAppType.name;
    return {
      title: this.currentAppType.name
        ? this.capitalizeFirstLetter(appName)
        : "",
      titleTemplate: this.currentAppType.name ? "Devcode - %s" : "Devcode",
    };
  },
  // metaInfo: {
  //   title: this.currentSlug,
  //   titleTemplate: "Devcode - %s"
  // },
  name: "Competition",
  components: {
    CBox,
    CGrid,
    CText,
    // CStack,
    CImage,
    CFormControl,
    CFormLabel,
    // CInput,
    // CRadioGroup,
    // CRadio,
    // ButtonBlue,
    Loading,
    // Loading2,
    // LeaderboardNew,
    // Select2,
    // LeaderboardFilters,
    Arena,
    // Draggable,
    // JobResultItem,
  },
  mixins: [validationMixin],
  data() {
    return {
      baseUrl: config.value("DEVCODE_WEB_URL"),
      isLoading: false,
      isUploading: false,
      isCancelUpload: false,
      isSubmitPressed: false,
      isReadySubmit: true,
      uploadRequest: null,
      mobileData: {},
      form: {
        siteName: "",
        siteUrl: "",
        driver: "",
        hashtags: [],
        database: "mysql",
      },
      statusForm: {
        siteName: false,
        siteUrl: false,
        driver: false,
        database: false,
      },
      tests: [],
      meta: {},
      isLoadingTest: false,
      testScenarios: [],
      page: 1,
      limit: 10,
      selectedTagList: [],
      defaultTimezone: config.value("DEFAULT_TIMEZONE"),
      appTypeView: {},
      defaultMetricsLabel: -1,
      defaultMetricsHeader: "Default Result",
      shareChallengeUrl: "",
    };
  },
  watch: {
    isSubmitPressed(val) {
      if (val && !this.isUploading) this.onSubmit();
    },
    isUploading(val) {
      if (!val && this.isSubmitPressed) this.onSubmit();
    },
    formHashtags() {
      this.setHashtagAlphaLetterOnly();
    },
    tests: function (current) {
      // set last hashtags field by current submission hashtags from history
      let me = this;
      if (me.form.hashtags.length < 1 && current.length > 0) {
        const { hashtags } = current[0];
        hashtags.map(({ name }) => {
          me.$refs.hashtagInput.select2
            .append(new Option(name, name, false, false))
            .trigger("change");
          me.form.hashtags.push(name);
        });
      }
    },
  },
  created() {
    const dataLayer = window.dataLayer || [];
    const gtag = () => dataLayer.push(arguments);
    gtag("js", new Date());
    gtag("config", "G-F2KHZP9RQ6");
    this.getData();
  },
  mounted() {
    // if (this.appTypeId == 0) {
    //   this.$router.push({ name: "404" });
    //   return;
    // }
    // console.log(this.$route.query?.hashtag);
    // if (this.$route.query?.hashtag) {}
    // prevent hashtag input only alpha letters characters
    this.setHashtagAlphaLetterOnly();
  },
  computed: {
    currentAppType() {
      return this.$store.getters.currentAppType;
    },
    currentTestScenarios() {
      return this.$store.getters.currentTestScenarios;
    },
    currentTestMetrics() {
      return this.$store.getters.currentAppMetrics;
    },
    currentMetricsLabelNames() {
      return this.defaultMetricsLabel >= 0
        ? this.$store.getters.currentAppMetrics[
            this.defaultMetricsLabel
          ].name.split(",")
        : [];
    },
    appTypeId() {
      return this.currentAppType.id;
    },
    formValidation() {
      const validation = {
        siteName: { required },
        siteUrl: { required },
      };
      if (this.currentAppType.type === "FE") {
        Object.assign(validation.siteUrl, { url });
      }
      if (this.currentAppType.type === "MOBILE") {
        Object.assign(validation, { driver: { required } });
      }

      if (this.currentAppType.type === "BE") {
        Object.assign(validation, { database: { required } });
      }
      // } else if (this.currentAppType.type === "BE") {
      //   Object.assign(validation.siteUrl, {
      //     url: function (value) {
      //       const url =
      //         /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(
      //           value
      //         );
      //       return url;
      //     },
      //   });
      // }
      return validation;
    },
    currentProgressBar() {
      return this.$store.getters.currentProgressBar;
    },
    currentTestSummary() {
      return this.$store.getters.currentTestSummary;
    },
    currentUser() {      
      return this.$store.getters.currentUser;
    },
    formHashtags() {
      return this.form.hashtags;
    },
  },
  methods: {
    applyFontSizes(htmlContent) {
      // Adding classes to headings
       const modifiedContent = htmlContent
        .replace(/<h1/g, '<h1 class="h1-custom"')
        .replace(/<h2/g, '<h2 class="h2-custom"')
        .replace(/<h3/g, '<h3 class="h3-custom"');
      return modifiedContent;
    },
    extractVideoId(youtubeUrl) {
      const videoId = youtubeUrl.split('?v=')[1];
      return videoId || '';
    },
    redirectToSignIn() {
      window.location.href = config.value("GETHIRED_WEB_URL") + `/signin?next=${window.location.href}` ;
    },
    setHashtagAlphaLetterOnly() {
      setTimeout(() => {
        this.$refs.hashtagInput.select2
          .data("select2")
          .selection.$search.on("keypress", function (e) {
            return (
              (e.charCode > 64 && e.charCode < 91) ||
              (e.charCode > 96 && e.charCode < 123) ||
              (e.charCode !== 32 && e.charCode !== 35)
            );
          });
      }, 50);
    },
    setClipboard() {
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = window.location;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      this.$toast({
        title: "Copied",
        status: "success",
        duration: 3000,
        position: "top",
        isClosable: false,
      });
    },
    changeLabelMetrics(index, header) {
      this.defaultMetricsLabel = index;
      this.defaultMetricsHeader = header;
    },
    hashtagOptions() {
      let me = this;
      return {
        multiple: true,
        templateResult: function ({ text }) {
          return `#${text}`;
        },
        escapeMarkup: function (markup) {
          return markup;
        },
        language: {
          noResults: function () {
            const ref = me.$refs.hashtagInput;
            const val = ref.select2.data("select2").selection.$search[0].value;
            const formattedVal = val.includes("#") ? val.slice(1) : val;

            if (formattedVal && formattedVal.trim().length >= 3) {
              const uniqId = Math.random().toString(36).slice(2);

              const removeDuplicateOption = function (value) {
                const options = document.querySelectorAll(
                  `option[value="${value}"]`
                );
                Array.prototype.map.call(options, (option) => option.remove());
              };

              // custom event listener for add new tag if not found
              const addNewItem = function () {
                const text = formattedVal.trim();

                removeDuplicateOption(text);

                ref.select2
                  .append(new Option(text, text, false, false))
                  .trigger("change");
                ref.select2
                  .data("select2")
                  .selection.$search.val("")
                  .trigger("keyup")
                  .trigger("click");

                me.form.hashtags = me.form.hashtags.filter(
                  (hashtag) => hashtag !== text
                );

                me.form.hashtags.push(text);
              };

              setTimeout(() => {
                let el = document.querySelector(".add-new-select2" + uniqId);
                if (el) {
                  el.removeEventListener("click", addNewItem);
                  el.addEventListener("click", addNewItem);
                }
              }, 100);

              return `<span>
                <span style='color: grey;'>Tambahkan tag </span>
                <span style='color: #16abf8; text-decoration: underline;'
                class='pointer add-new-select2${uniqId}'>#${formattedVal}</span>
              </span>`;
            }
            return "Not Found";
          },
        },
        ajax: {
          transport: function (params, success, failure) {
            ApiService.get("/hashtags", {
              name: params?.data?.q,
              page: 1,
              limit: 10,
            })
              .then(({ data }) => success(data))
              .catch((err) => failure(err));
          },
          processResults: function (data) {
            return {
              results:
                data?.data.map((r) => {
                  return { id: r.name, text: r.name };
                }) ?? [],
            };
          },
        },
      };
    },
    openApp(url) {
      window.open(url, "_blank");
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    resetProgressBar() {
      this.$store.dispatch(RESET_PROGRESS_BAR, { progressName: "progressApp" });
    },
    cancelUpload() {
      this.isCancelUpload = true;
      this.request.cancel();
      this.mobileData = {};
      this.form.siteUrl = "";
    },
    dispatchUploadProcess(event) {
      if (this.request) this.cancelUpload();
      this.uploadProcess(event);
    },
    async uploadProcess(event) {
      const axiosSource = axios.CancelToken.source();
      this.request = { cancel: axiosSource.cancel };
      try {
        this.isUploading = true;
        const upload = await this.$store.dispatch(UPLOAD_APP, {
          data: event.target.files[0],
          progressName: "progressApp",
          cancelToken: axiosSource,
        });
        this.form.siteUrl = "https://" + upload.location;
        this.mobileData = upload.apk;
        this.isUploading = false;
      } catch (err) {
        this.isUploading = false;
        let errData = err.message;
        if (err.response && err.response.data && err.response.data.message)
          errData = err.response.data.message;

        if (!this.isCancelUpload) {
          Swal.fire({
            title: "Oops . .",
            text: errData,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        }

        this.resetProgressBar();
        this.isCancelUpload = false;
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    nextData() {
      this.page = this.page + 1;
      this.getTests(true);
    },
    checkEmpObject(obj) {
      return _.isEmpty(obj);
    },
    changeStatus(name) {
      this.statusForm[name] = true;
    },
    async getData() {
      let appType;
      try {
        this.isLoading = true;
        appType = await this.$store.dispatch(
          GET_APP_TYPE,
          this.$route.params.slug
        );
        // await this.getAppTypeView(this.appTypeId);
        // await this.getTests();
        // await this.$store.dispatch(
        //   GET_APP_METRICS,
        //   this.currentAppType.app_name
        // );
        // await this.$store.dispatch(GET_TEST_SCENARIOS, {
        //   appId: this.appTypeId,
        // });
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.isLoading = false;
        if (appType.view_type == "RECRUITMENT" && !this.appTypeView?.token) {
          Swal.fire({
            text: "Maaf, anda perlu login di GetHired.id terlebih dahulu. Akses halaman ini kembali setelah anda melakukan Login pada GetHired.id.",
            heightAuto: true,
            willClose: () => {
              window.location.href =
                config.value("GETHIRED_WEB_URL") + "/employee/profile";
            },
          });
        }
        // console.log(err);
        // Swal.fire({
        //   title: "Hi ..",
        //   text: "Selamat datang di Devcode perhatikan requirement dan detail test scenario yang ada",
        //   heightAuto: true,
        //   willClose: () => {
        //     this.reloadData();
        //   },
        // });
      }
      this.shareChallengeUrl =
        this.baseUrl + "/arena/" + this.$route.params.slug;
    },
    reloadData() {
      this.getData();
    },
    formatToLocalDate(date) {
      return (
        new Date(date).toLocaleString("id-ID", {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          timeZone: this.defaultTimezone,
        }) + " WIB"
      );
    },
    formatExpiredDate(type, date) {
      const endDate = moment(date).tz(this.defaultTimezone);
      // const maximumSubmitDate = moment(this.appTypeView.expired_date).tz(
      //   this.defaultTimezone
      // );

      if (type === "COMPETITION") {
        return moment(endDate).format("DD MMMM YYYY HH:mm z");
      } else {
        return this.formatToLocalDate(this.appTypeView.expired_date);
        // return  moment(maximumSubmitDate)
        //   .set({ h: 24, m: 0 })
        //   .format("DD MMMM YYYY HH:mm z");
      }
    },
    formatStartDate(date) {
      return moment(date).tz(this.defaultTimezone);
    },
    isChallengeBegin() {
      let result = false;

      if (this.currentAppType.start_date) {
        const startDate = this.formatStartDate(this.currentAppType.start_date);

        const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
        const timeZone = moment.tz.guess();
        const convertedTime = moment.tz(currentTime, timeZone).tz("Asia/Jakarta").format('YYYY-MM-DD HH:mm:ss');

        const diffMinutes = moment(startDate, "DD MMMM YYYY HH:mm")
          .diff(convertedTime, "minutes");

        diffMinutes <= 0 ? (result = true) : (result = false);
        return result;
      }

      return result;
    },
    isReachDeadline() {
      let result = false;

      if (
        this.currentAppType.expired_date ||
        this.currentAppType.participant_duration
      ) {
        const expiredDate = this.formatExpiredDate(
          this.currentAppType.view_type,
          this.currentAppType.expired_date
        );

        const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
        const timeZone = moment.tz.guess();
        const convertedTime = moment.tz(currentTime, timeZone).tz("Asia/Jakarta").format('YYYY-MM-DD HH:mm:ss');

        const diffMinutes = moment(expiredDate, "DD MMMM YYYY HH:mm z").diff(
          convertedTime,
          "minutes"
        );

        diffMinutes <= 0 ? (result = true) : (result = false);
        return result;
      }

      return result;
    },
    isNotStarted() {
      let result = false;
      if (this.currentAppType.view_type === "RECRUITMENT") {
        const start_date = this.appTypeView.created_at;
        result = new Date(start_date) > new Date();
      }
      return result;
    },
    isTokenExpired() {
      let result = false;
      if (this.currentAppType.view_type === "RECRUITMENT") {
        const expired_date = this.appTypeView.expired_date;
        result = new Date() > new Date(expired_date);
      }
      return result;
    },
    async getTests(showError = false) {
      try {
        this.isLoadingTest = true;
        await Promise.all([
          this.$store.dispatch(GET_TEST_SUMMARY, this.appTypeId),
          this.$store.dispatch(GET_TESTS, {
            appTypeId: this.appTypeId,
            page: this.page,
            limit: this.limit,
          }),
        ]);
        const tests = this.$store.getters.currentTests;
        this.isLoadingTest = false;
        this.tests = tests.data;
        this.meta = tests.meta;
      } catch (err) {
        this.isLoadingTest = false;
        if (showError) {
          Swal.fire({
            title: "Oops . .",
            text: err.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        }
      }
    },
    async getAppTypeView(appTypeId) {
      try {
        const res = await this.$store.dispatch(GET_APP_TYPE_VIEW, {
          app_type_id: appTypeId,
        });

        this.appTypeView = res;
      } catch (err) {
        // const code = err.response.status;
        // if (code === 403) {
        //   Swal.fire({
        //     text: "Maaf, Anda tidak memiliki akses",
        //     heightAuto: true,
        //     willClose: () => {
        //       window.location.href = "/";
        //     },
        //   });
        // }
        // if (res.view_type == 'RECRUITMENT' && code === 401) {
        //   Swal.fire({
        //     text: "Maaf, anda perlu login di GetHired.id terlebih dahulu. Akses halaman ini kembali setelah anda melakukan Login pada GetHired.id",
        //     heightAuto: true,
        //     willClose: () => {
        //       window.location.href =
        //         config.value("GETHIRED_WEB_URL") + "/employee/profile";
        //     },
        //   });
        // }
      }
    },
    mobileAppValidation() {
      if (this.currentAppType.type === "MOBILE") {
        if (this.form.driver === "flutter" && !this.mobileData.debuggable) {
          Swal.fire({
            title: "Oops . .",
            text: this.currentAppType.alertMessage.notDebuggable,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.isLoading = false;
          this.isReadySubmit = false;
          this.isSubmitPressed = false;
        } else if (this.mobileData.usesSdk.targetSdkVersion < 30) {
          Swal.fire({
            title: "Oops . .",
            text: this.currentAppType.alertMessage.belowtargetSdk,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.isLoading = false;
          this.isReadySubmit = false;
          this.isSubmitPressed = false;
        } else {
          this.isReadySubmit = true;
        }
      }
    },
    onSubmit() {
      if (this.currentAppType.type === "LOGIC") {
        const token =
          this.$router.history.current.query?.token || this.appTypeView.token;
        if (token) {
          const logicUrl = config.value("DEVCODE_LOGIC_TEST_URL");
          const url = `${logicUrl}/playground/intro/${token}`;
          window.open(url, "_blank");
        } else {
          Swal.fire({
            title: "Oops . .",
            text: "You need token to start logic test challenge",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        }
      }

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        for (const d in this.statusForm) {
          this.statusForm[d] = true;
        }

        this.isSubmitPressed = false;
        return;
      }

      this.isLoading = true;
      this.mobileAppValidation();

      const formUsed = {
        ...this.form,
        userName: "dummy",
        appId: this.appTypeId.toString(),
        type: this.currentAppType.type,
      };

      if (this.isReadySubmit) {
        this.$store
          .dispatch(POST_TEST, formUsed)
          .then((data) => {
            this.form.siteName = "";
            this.form.siteUrl = "";
            for (const d in this.statusForm) {
              this.statusForm[d] = false;
            }

            this.isLoading = false;
            this.resetProgressBar();
            this.$router.push({
              name: "Detail Job",
              params: { id: data.test_id },
            });

            (async () => {
              // onesignal show prompt notification
              this.$OneSignal.init({
                appId: config.value("ONESIGNAL_APP_ID"),
                safari_web_id: config.value("ONESIGNAL_SAFARI_ID"),
                notifyButton: {
                  enable: true,
                },
                allowLocalhostAsSecureOrigin: true,
              });
              await this.$OneSignal.showNativePrompt();
              if (await this.$OneSignal.isPushNotificationsEnabled()) {
                const { emp_id } = this.currentUser;
                if (emp_id) {
                  this.$OneSignal.setExternalUserId(`emp/${emp_id}`);
                } else {
                  alert("Failed to set external user id for push notification");
                }
              } else {
                this.$OneSignal.removeExternalUserId();
              }
            })();

            // Swal.fire({
            //   title: "OK",
            //   text: "Berhasil submit test",
            //   icon: "success",
            //   confirmButtonClass: "btn btn-secondary",
            //   heightAuto: false,
            // });
            // this.getTests();
          })
          .catch((err) => {
            this.isLoading = false;
            let errData = err.message;
            if (err.response && err.response.data && err.response.data.message)
              errData = err.response.data.message;
            Swal.fire({
              title: "Oops . .",
              text: errData,
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          });
      }

      this.isSubmitPressed = false;
    },
  },
  validations() {
    return {
      form: this.formValidation,
    };
  },
};
</script>

<style>
.h1-custom {
  font-size: 24px;
  /* Your custom font size for h1 */
}
.h2-custom {
  font-size: 20px;
  /* Your custom font size for h2 */
}
.h3-custom {
  font-size: 16px;
  /* Your custom font size for h3 */
}
</style>
