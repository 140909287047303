import { render, staticRenderFns } from "./Arena.vue?vue&type=template&id=53910dd1"
import script from "./Arena.vue?vue&type=script&lang=js"
export * from "./Arena.vue?vue&type=script&lang=js"
import style0 from "./Arena.vue?vue&type=style&index=0&id=53910dd1&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CBox: require('@chakra-ui/vue').CBox, CDivider: require('@chakra-ui/vue').CDivider, CFormLabel: require('@chakra-ui/vue').CFormLabel, CGrid: require('@chakra-ui/vue').CGrid, CFormControl: require('@chakra-ui/vue').CFormControl, CImage: require('@chakra-ui/vue').CImage, CButton: require('@chakra-ui/vue').CButton})
