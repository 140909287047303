var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CBox',_vm._l((_vm.filterLabels),function(label,i){return _c('CBox',{key:i},[_c('CText',{attrs:{"py":"4px","mx":"20px","mb":"2","fontFamily":"Montserrat","fontStyle":"normal","fontSize":"14px","fontWeight":"600","color":"fontMain"}},[_vm._v(" Pilih Arena ")]),_c('CGrid',{attrs:{"templateColumns":[
        '12fr',
        '6fr 6fr',
        '3fr 3fr 3fr 3fr',
        '3fr 3fr 3fr 3fr',
      ],"gap":[2, 2, 6, 6],"w":"100%"}},_vm._l((_vm.displayedFilters[label.toLowerCase()]),function(filter,idx){return _c('CBox',{key:idx,attrs:{"bg":"white","minH":"100px","py":"20px","px":"20px","borderRadius":"5px","boxShadow":"0px 4px 10px rgba(0, 0, 0, 0.1)","mb":"5"}},[_c('CText',{staticClass:"pointer",attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"18px","fontWeight":600,"color":"activeMenu"},nativeOn:{"click":function($event){return _vm.$router.push({
              name: 'CompetitionArena',
              params: {
                id: _vm.id,
                arena: filter.slug,
              },
            })}}},[_vm._v(" "+_vm._s(filter.name)+" ")]),_c('CText',{attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"14px"}},[_vm._v(" "+_vm._s(filter.description)+" ")])],1)}),1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }