var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CBox',[_c('CBox',[_c('Loading',{attrs:{"active":_vm.isLoading}}),_c('CGrid',{attrs:{"templateColumns":_vm.currentAppType.view_type == 'RECRUITMENT'
          ? '12fr'
          : ['12fr', '12fr', '12fr', '12fr'],"gap":[3, 3, 6, 6]}},[_c('CBox',[_c('CBox',{attrs:{"bg":"white","borderRadius":"10px","boxShadow":"0px 4px 10px rgba(0, 0, 0, 0.1)"}},[_c('CBox',{attrs:{"minH":"10px"}},[_c('CBox',{attrs:{"py":"20px","px":"20px"}},[_c('CText',{attrs:{"whiteSpace":"wrap","fontFamily":"Montserrat","fontSize":['20px', '24px', '34px', '36px'],"fontWeight":"bolder","color":"fontMain"}},[_vm._v(_vm._s(_vm.currentAppType.name))])],1)],1),_c('CDivider'),(_vm.isReachDeadline() || !_vm.isChallengeBegin())?_c('CBox',{attrs:{"px":"10px","py":"10px","borderRadius":"4px","bgColor":"bgFailedState"}},[_c('CText',{attrs:{"display":"inline","fontFamily":"Montserrat","color":"failedColor","fontWeight":"bold","fontSize":"14px"}},[_vm._v(_vm._s(!_vm.isChallengeBegin() ? _vm.currentAppType.alert_message.submissionNotStarted + " - " + this.formatToLocalDate(_vm.currentAppType.start_date) : _vm.currentAppType.alert_message.submissionClosed))])],1):_vm._e(),(!_vm.currentUser)?_c('CBox',{attrs:{"px":"10px","py":"10px","borderRadius":"4px"}},[_c('CText',{attrs:{"display":"inline","fontFamily":"Montserrat","color":"failedColor","fontWeight":"bold","fontSize":"14px"}},[_vm._v(_vm._s(_vm.currentAppType.alert_message.unauthorizedUser)+","),_c('span',{staticStyle:{"color":"#29aafe","cursor":"pointer"},on:{"click":_vm.redirectToSignIn}},[_vm._v(" Click here")]),_vm._v(" to login")])],1):_vm._e(),(_vm.currentAppType.type !== 'LOGIC')?_c('CBox',{attrs:{"px":"20px","pt":"40px","pb":"20px"}},[_c('CText',{attrs:{"fontFamily":"Montserrat","fontSize":"20px","color":"fontMain","fontWeight":"bold"}},[_vm._v("Submit Form")])],1):_vm._e(),(
              !_vm.checkEmpObject(_vm.currentAppType) &&
              _vm.currentAppType.type !== 'LOGIC'
            )?_c('CBox',{attrs:{"px":"20px"}},[(_vm.currentAppType.expired_date)?_c('CFormControl',{attrs:{"mb":"20px"}},[_c('CGrid',{attrs:{"templateColumns":['12fr', '12fr', '1fr 3fr', '1fr 3fr'],"gap":[0, 0, 6, 6],"w":"100%"}},[_c('CFormLabel',{attrs:{"fontFamily":"Montserrat","color":"fontMain","fontSize":"14px","fontWeight":"bold","d":"flex","alignItems":"center"}},[_vm._v(_vm._s(_vm.currentAppType.form_label.deadline))]),_c('CText',{attrs:{"fontFamily":"Montserrat","color":"fontMain","fontWeight":"550","fontSize":"14px"}},[_vm._v(" "+_vm._s(_vm.formatExpiredDate( _vm.currentAppType.view_type, _vm.currentAppType.expired_date )))])],1)],1):_vm._e()],1):_vm._e(),_c('CBox',{attrs:{"d":"flex","justifyContent":"flex-end","m":[
              '20px 20px 13px 20px',
              '20px 20px 13px 20px',
              '20px 20px 20px 20px',
              '20px 20px 20px 20px',
            ],"pb":"20px"}},[_c('CButton',{attrs:{"variant":"outline","backgroundColor":"#fff","mr":"2","color":"main","borderColor":"main","fontSize":"14px"},on:{"click":function($event){return _vm.setClipboard()}}},[_c('CImage',{attrs:{"src":require('@/assets/icon/copy.svg'),"w":"18px","h":"18px","mr":"2"}}),_vm._v(" Copy Challenge URL ")],1)],1)],1)],1)],1)],1),(_vm.currentAppType.view_type != 'RECRUITMENT')?_c('CBox',{attrs:{"pb":"2em"}},[(_vm.currentAppType.id)?_c('Arena',{attrs:{"id":parseInt(_vm.currentAppType.id)}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }